<template>
  <div class="container px-6">
    <div class="flex min-h-screen items-center justify-center">
      <div v-if="denied" class="bg-white rounded shadow p-6">
        <h1 class="mb-3 font-bold">Login Failed</h1>
        <p class="mb-6">You must grant the marketing packs dashboard access to your Nurtur account.</p>
        <div class="flex justify-end">
          <primary-btn @click="start">
            Try Again
          </primary-btn>
        </div>
      </div>
      <div v-if="unauthorized" class="bg-white rounded shadow p-6">
        <h1 class="mb-3 font-bold">Login Failed</h1>
        <p class="mb-6">Your account does not have access to the marketing packs dashboard.</p>
        <div class="flex justify-end">
          <primary-btn @click="start">
            Log Out
          </primary-btn>
        </div>
      </div>
      <div v-if="failed" class="bg-white rounded shadow p-6">
        <h1 class="mb-3 font-bold">Login Failed</h1>
        <p class="mb-6">{{ failed }}</p>
        <div class="flex justify-end">
          <primary-btn @click="start">
            Try Again
          </primary-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import PrimaryBtn from "@/components/PrimaryBtn";
import Str from 'laravel-js-str';

export default {
  components: { PrimaryBtn },
  data () {
    return {
      denied: this.$route.query.error === 'access_denied',
      unauthorized: this.$route.query.error === 'unauthorized',
      failed: null
    }
  },
  created () {
    if (this.denied || this.unauthorized) {
      return
    }

    this.init()
  },
  methods: {
    ...mapActions(['fetchAuthUser', 'requestAuthorization', 'getToken']),
    ...mapMutations(['setInitialized']),
    async init () {
      const state = this.$route.query.state
      const code = this.$route.query.code
      const intended = localStorage.intended || '/'
      if (!code) {
        return this.start(this.$route.query.force !== '0' && this.$route.query.referrer !== 'ecosystem', this.$route.query.referrer === 'ecosystem')
      }

      try {
        await this.getToken({ state, code })
      } catch (e) {
        localStorage.clear()
        this.failed = e
        return
      }

      const user = await this.fetchAuthUser()
      if (user.type != 7) {
        localStorage.clear()

        this.unauthorized = true
        return
      }

      this.setInitialized();
      
      localStorage.removeItem('intended')
      this.$router.replace(Str.startsWith(intended, '?') ? '/' : intended)
    },
    start (force, direct) {
      return this.requestAuthorization({ force, direct })
    }
  },
}
</script>
